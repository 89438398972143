@use '@angular/material' as mat;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

/* ----- CUSTOMIZE LEAFLET LAYER POPUP ----------- */
.leaflet-control-zoom {
  top: 70px;
}

.leaflet-control-layers.leaflet-control {
  position: fixed;
  left: 110px !important;
  top: 60px !important;
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
  display: none;
}

/* -------------------PING--------------------------- */
.ping-red {
  stroke: #333;
  fill: red;
  stroke-width: 1px;
}

/* -------------------TIME DIMENSION--------------------------- */
a.leaflet-control-timecontrol.timecontrol-date {
  min-width: auto !important;
}

.leaflet-bar-timecontrol .timecontrol-speed .speed {
  margin-right: 2px;
}

.timecontrol-slider .slider {
  margin-top: 11px;
  margin-left: 10px;
  height: 2px;
  border-color: #0059b3;
}

.leaflet-bar-timecontrol .timecontrol-dateslider .slider {
  @include media-breakpoint-down(xl) {
    width: 100px !important;
  }
}

.timecontrol-slider .knob {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  margin-top: -7px;
  margin-left: -12px;
  border: 0;
  background-color: #0059b3;
}

.leaflet-control-attribution {
  bottom: 40px;
}

@include media-breakpoint-only(xs) {
  .leaflet-bar.leaflet-bar-horizontal.leaflet-bar-timecontrol.leaflet-control {
    position: fixed;
    top: 37px;
    left: -11px;
  }
}
