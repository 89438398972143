@use "@angular/material" as mat;
@use "footer-theme" as footer;
@use "am-components" as am-components;
//@import "@angular/material/theming";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "leaflet/dist/leaflet.css";
@import "leaflet-timedimension/dist/leaflet.timedimension.control.css";
@import "leaflet";
@import "typography";

@include mat.core();

$radar-palette-color: (
    50: #0059b3,
    100: #fff,
    200: #b6aef3,
    300: #988dee,
    400: #8274eb,
    500: #99bbe8,
    600: #6454e4,
    700: #594ae0,
    800: #4f41dd,
    900: #003160,
    A100: #fff,
    A200: #e9e7ff,
    A400: #b9b4ff,
    A700: #a29bff,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #000,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    ),
);
$radar-primary: mat.define-palette($radar-palette-color, 50);
$radar-accent: mat.define-palette(mat.$amber-palette);
$radar-warn: mat.define-palette(mat.$red-palette);
$radar-theme: mat.define-light-theme(
    (
        color: (
            primary: $radar-primary,
            accent: $radar-accent,
        ),
    )
);

.icon {
    color: mat.get-color-from-palette($radar-palette-color, 100);
    cursor: pointer;
}

.icon-info {
    color: mat.get-color-from-palette($radar-palette-color, 50);
    cursor: pointer;
}

.font-color {
    color: mat.get-color-from-palette($radar-palette-color, 900);
}

.font-color-white {
    color: mat.get-color-from-palette($radar-palette-color, 100);
}

.mat-toolbar {
    background: mat.get-color-from-palette($radar-primary, 50) !important;
    opacity: 80%;
    position: absolute;
    z-index: 2 !important;
    width: 100% !important;
}

@include footer.theme($radar-theme);
@include am-components.theme($radar-theme);
