@use "sass:map";
@use "@angular/material" as mat;
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

@mixin color($theme) {
    $primary: map.get($theme, primary);

    .button-style-download {
        background-color: mat.get-color-from-palette($primary, 50) !important;
        color: mat.get-color-from-palette($primary, 100) !important;
    }

    .button-style-navigator {
        background-color: mat.get-color-from-palette($primary, 100) !important;
        color: mat.get-color-from-palette($primary, 50) !important;
    }
}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config {
        @include color($theme);
    }
}

.mat-mdc-slide-toggle {
    line-height: 24px;
}

.mat-mdc-dialog-surface {
    //padding: 20px
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0 !important;
}

.mat-sidenav {
    background-color: #fff;
}

.mat-drawer:not(.mat-drawer-side) {
    box-shadow:
        0 8px 10px -5px rgb(0 0 0 / 20%),
        0 16px 24px 2px rgb(0 0 0 / 14%),
        0 6px 30px 5px rgb(0 0 0 / 12%);
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
    top: 6px !important;
    left: 28px !important;
    width: 24px;
}

.mat-icon-button {
    width: auto !important;
}

.mat-icon:not(.icon-close) {
    font-size: 36px;
    width: 36px !important;
    height: 36px !important;

    @include media-breakpoint-down(lg) {
        font-size: 24px;
        width: 24px !important;
        height: 24px !important;
    }
}
