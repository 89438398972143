@use "@angular/material" as mat;
@import url("https://fonts.googleapis.com/css?family=Titillium+Web:300,400,400i,600,600i,700&display=swap");

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */

$custom-typography: mat.define-typography-config(
    $headline-1: mat.define-typography-level(14px, 32px, 500, "Titillium Web"),
    $headline-2: mat.define-typography-level(10px, 32px, 500, "Titillium Web"),
    $headline-3: mat.define-typography-level(12px, 20px, 500, "Titillium Web"),
    $subtitle-2: mat.define-typography-level(6px, 20px, 500, "Titillium Web"),
);

.leaflet-bar.leaflet-bar-horizontal.leaflet-bar-timecontrol.leaflet-control {
    @include mat.typography-level($custom-typography, "headline-1");

    @include media-breakpoint-down(xl) {
        @include mat.typography-level($custom-typography, "headline-2");
    }

    .leaflet-container {
        @include mat.typography-level($custom-typography, "headline-3");
    }
}
