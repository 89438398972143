@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $primary: map.get($theme, primary);

  .footer {
    background: mat.get-color-from-palette($primary, 50) !important;
    opacity: 80%;
    position: absolute;
    bottom: 0;
    z-index: 999 !important;
    width: 100% !important;
    height: 40px;
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  @if $color-config {
    @include color($theme);
  }
}
